import { graphql } from "gatsby"
import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"
import DownloadLink from "@components/downloadLink"

export const query = graphql`
  query SchoolScheduleQuery {
    allStrapiPlanLekcji {
      edges {
        node {
          plik_planu_lekcji {
            size
            url
            id
            ext
            name
          }
        }
      }
    }
  }
`

function SchoolSchedule({ data }) {
  return (
    <Layout>
      <SEO title="Plan lekcji - ZSP Nidek" />
      <h1>Plan lekcji</h1>
      <DownloadLink
        data={data}
        strapiCollection="allStrapiPlanLekcji"
        strapiName="plik_planu_lekcji"
      >
        Plan lekcji
      </DownloadLink>
    </Layout>
  )
}

export default SchoolSchedule
